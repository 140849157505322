import React from "react";
import "./layers.scss";
import { Container, Draggable } from "react-smooth-dnd";
import ControlFunctionOpenlayerType from "../../../../components/open-layer/control-function-type/control-function-type";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as InitMapStore from "../../../../redux/store/init-map/init-map.store";
import ModalLayerSetting from "./modal-setting/modal-setting";
import ModalEditSettingLayer from "./modal-edit-layer/modal-edit-layer";
import ConfirmModalView from "../../../../components/confirm-modal/confirm-modal";
import OpenlayerCommand from "../../../../components/open-layer/control-function-type/control-function-type";

//--- Material Control
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import { Tooltip, IconButton, makeStyles, Typography } from "@material-ui/core";

//--- Material Icon
import AddCircleIcon from "@material-ui/icons/AddCircle";
import LayersIcon from "@material-ui/icons/Layers";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import EditIcon from "@material-ui/icons/Edit";
import TuneIcon from "@material-ui/icons/Tune";
import DeleteIcon from "@material-ui/icons/Delete";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import CloseIcon from "@material-ui/icons/Close";
import $ from 'jquery';

class RenderLayerControlView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfirmModal: false,
      confirmModalTitle: "",
      confirmModalHandleAccept: null,
      dataSourcLayerSelected: "",
      listLayers: this.props.data,
      isOpenSettingGroupLayers: false,
      parentId:0,
      addParentId: 0,
      addParentLevel:0,
      currentLayerLevel:0,
      currentEditGroupId:0,
      isCreateNewGroupLayers: false,
      isOpenSettingLayer: false,
      isCreateNewLayer: false,
      groupIndexSelected: null,
      layerIndexSelected: null,
      layerEditingId:0,
      groupLayersName: "",
      idGroupLayerSelected: null,
      indexGroupSelected: null,
      hasShowWarningGroupLayerSetting: false,
      layerSettingViewType: 0, // view - 3, filter - 2, setting - 1, data source -0
    };
  }

  onSetDataSourceLayerSelected = (dataSource) =>
    this.setState({ dataSourcLayerSelected: dataSource });

  getChildPayloadGrouplayer = (index) => {
    return this.getLayerData.getLayerGroupByIndex(index);
  };

  getChildPayloadLayer = (layerId) => {
    return this.getLayerDataByLayerId(layerId)
  };

  onDropGroupLayer = (event) => {
    const ListGroupLayers = this.getLayerData.getListGroupLayers();
    ListGroupLayers.splice(event.removedIndex, 1);
    ListGroupLayers.splice(event.addedIndex, 0, event.payload);
    this.autoUpdateLayerData();
  };

  onDropLayer = (event, groupId) => {
    //let categoryList = this.props.layerData.layer_categories;

    if (event.removedIndex != null && event.addedIndex != null){
      const ListGroupLayer = this.getLayerSettingListByGroupId(groupId);
      const editGroupLayer = [...ListGroupLayer];
      if(Array.isArray(editGroupLayer) && editGroupLayer.length >0){
        const removeItem = ListGroupLayer[event.removedIndex];
        const addItem = ListGroupLayer[event.addedIndex];
        editGroupLayer.splice(event.removedIndex,1,addItem);
        editGroupLayer.splice(event.addedIndex,1,removeItem);

        for(let i=0; i< editGroupLayer.length; i++){
          editGroupLayer[i]['level'] = i;
        }
        this.updateSortLayerList(groupId,editGroupLayer)
      }
    }
  };

  updateSortLayerList = (groupId,updateLayerList) => {
    let categoryList = this.props.layerData.layer_categories;
    let newCategory = this.updateLayerCategory(categoryList,groupId,updateLayerList);
    this.props.layerData.layer_categories = newCategory;
    this.autoUpdateLayerData();
  }

  updateLayerCategory = (categoryList,groupId,updateLayerList) => {
    let isDone = false;
    if(Array.isArray(categoryList) && categoryList.length >0 && isDone == false){
      for(let i=0; i< categoryList.length; i++){
        if(categoryList[i].children == null){
          categoryList[i].children = [];
        }
        if(categoryList[i].id == groupId && isDone == false){
          categoryList[i].layer_settings = updateLayerList;
          isDone = true;
        }

        if(Array.isArray(categoryList[i].children) && categoryList[i].children.length >0  && isDone == false){
          categoryList[i].children = this.updateLayerCategory(categoryList[i].children,groupId, updateLayerList);
        }
      }
    }

    return categoryList;
  }

  onClickAddNewGroup = (event,parentId,level) => {
    event.stopPropagation();
    this.setState({
      groupLayersName: "",
      isOpenSettingGroupLayers: true,
      isCreateNewGroupLayers: true,
    });
    if(parentId >0){
      this.setState({
        parentId:parentId,
        addParentId:parentId,
        addParentLevel:level
      });
    }
  };

  onClickSettingGroupLayer = (event, indexGroup, groupId) => {
    event.stopPropagation();
    this.state.currentEditGroupId = groupId;
    this.setState({
      isOpenSettingGroupLayers: true,
      isCreateNewGroupLayers: false,
      indexGroupSelected: indexGroup,
      groupLayersName: this.getGroupLayerNameByGroupId(groupId),
    });
  };

  getGroupLayerNameByGroupId = (groupId) => {
    let flatLayerList = this.props.flatLayerList;
    let groupName = null;
    if(Array.isArray(flatLayerList) && flatLayerList.length >0){
      flatLayerList.map((layer) => {
          if(layer.id == groupId){
            groupName = layer.folder_name;
          }
      })
    }
    return groupName;
  }

  onClickDeleteGrouplayer = () => {
    let groupId = this.state.currentEditGroupId;
    let categoryList = this.props.layerData.layer_categories;
    categoryList = this.removeGroupLayer(categoryList,groupId);
    this.props.layerData.layer_categories = categoryList;
    this.autoUpdateLayerData();
    this.setState({
      isOpenSettingGroupLayers: false,
    });
  };

  removeGroupLayer = (layerData,groupId) => {
    let returnData = [];
    if(layerData && Array.isArray(layerData) && layerData.length >0){
      for(let i=0; i<layerData.length; i++){
        if(layerData[i].id != groupId){
          returnData.push(layerData[i]);
        }
      }
    }

    if(Array.isArray(returnData) && returnData.length >0){
      for(let i=0; i<returnData.length; i++){
        if(Array.isArray(returnData[i].children) && returnData[i].children.length >0){
          returnData[i].children = this.removeGroupLayer(returnData[i].children,groupId)
        }
      }
    }

    return returnData;
  }

  onClickOpenAddNewLayer = (event, groupLayerIndex,groupId) => {
    event.stopPropagation();
    this.setState({
      isOpenSettingLayer: true,
      isCreateNewLayer: true,
      groupIndexSelected: groupLayerIndex,
      parentId: groupId,
    });
  };

  onClickEditLayer = (event, layerId) => {
    event.stopPropagation();
    this.setState({
      isOpenSettingLayer: true,
      isCreateNewLayer: false,
      layerEditingId: layerId,
    });
  };

  handleChangeSettingLayerType = (event, newValue) => {
    this.setState({
      layerSettingViewType: newValue,
    });
  };

  markLayerChecked = (category,objectId) => {
    let layerSettingList    = category.layer_settings;
    let childrenList          = category.children;

    if(Array.isArray(childrenList) && childrenList.length >0){
      for(let j=0;j<childrenList.length;j++){
        childrenList[j] = this.markLayerChecked(childrenList[j],objectId);
      }
    }

    if(Array.isArray(layerSettingList) && layerSettingList.length >0){
      for(let i=0; i < layerSettingList.length; i++){
        if(layerSettingList[i].id == objectId){
          layerSettingList[i].is_check = !layerSettingList[i].is_check;
          break;
        }
      }
    }
    category.children = childrenList;
    category.layer_settings = layerSettingList;
    return category;
  }

  onClickCheckBoxLayer = (layerObject, indexLayer, indexGroup) => {
    let categoryList = this.props.layerData.layer_categories;
    for(let i=0; i<categoryList.length;i++){
        categoryList[i] = this.markLayerChecked(categoryList[i],layerObject.id);
    }
    this.props.layerData.layer_categories = categoryList;
    this.autoUpdateLayerData();
    this.toggleDisplayLayerAction(layerObject);
  };

  toggleDisplayLayerAction = (layerObject) => {
    this.props.selectToggleLayerFunction(
      ControlFunctionOpenlayerType.ToggleDisplayLayer,
      {
        layer: layerObject,
      }
    );
  };

  handleAddNewLayer = (newLayer) => {
    if (newLayer.is_check){
      this.toggleDisplayLayerAction(newLayer);
    }
    this.props.layerData.layer_categories = this.pushNewLayerToParentLayer(
        this.props.layerData.layer_categories,newLayer
    );
  };

  pushNewLayerToParentLayer = (settingList,newLayer) => {
    let parentId = this.state.parentId;
    if(Array.isArray(settingList) && settingList.length >0){
      for(let i=0;i<settingList.length;i++){
        if(settingList[i].id == parentId){
          settingList[i].layer_settings.push(newLayer);
        }else{
          if(Array.isArray(settingList[i].children) && settingList[i].children.length >0){
            settingList[i].children = this.pushNewLayerToParentLayer(settingList[i].children,newLayer);
          }
        }
      }
    }
    return settingList;
  }

  updateEditedLayerToLayerDataList = (dataList,dataLayerEdited,layerId) => {
    if(Array.isArray(dataList) && dataList.length >0){
      for(let i=0;i<dataList.length;i++){
        let isUpdate = 0;
        let settingList = dataList[i].layer_settings;
        if(Array.isArray(settingList) && settingList.length >0){
          for(let j=0; j < settingList.length; j++){
            if(settingList[j].id == layerId){
              isUpdate = 1;
              settingList[j] = dataLayerEdited;
            }
          }
          dataList[i].layer_settings = settingList;
        }
        if(isUpdate == 0 && Array.isArray(dataList[i].children) && dataList[i].children.length >0){
          dataList[i].children = this.updateEditedLayerToLayerDataList(dataList[i].children,dataLayerEdited,layerId);
        }
      }
    }
    return dataList;
  }

  handleClickAcceptSettingGrouplayer = () => {
    let currentEditGroupId = this.state.currentEditGroupId;
    if (!this.state.groupLayersName.trim()) {
      if (!this.state.hasShowWarningGroupLayerSetting)
        this.setState({ hasShowWarningGroupLayerSetting: true });
      return;
    }

    if (this.state.isCreateNewGroupLayers) {
      let newGroup = {};
      if(this.state.addParentId >0){
          newGroup = {
            folder_label: this.state.groupLayersName,
            folder_name: this.state.groupLayersName,
            id: 0,
            layer_settings: [],
            level: 0,
            parent_id: this.state.addParentId,
          }
              this.props.layerData.layer_categories = this.addNewGroupToLayerData(
                  this.props.layerData.layer_categories,
                  this.state.addParentId,
                  newGroup
              );
      }else{
        newGroup = {
          folder_label: this.state.groupLayersName,
          folder_name: this.state.groupLayersName,
          id: 0,
          layer_settings: [],
          level: 0,
          map_setting_id: this.getLayerData.getLayerData().map_id,
        }
        this.getLayerData.getListGroupLayers().push(newGroup);
      }

    } else {
      let categoryList = this.props.layerData.layer_categories;
      this.props.layerData.layer_categories = this.updateGroupNameToLayerData (
                                        categoryList,
                                        this.state.currentEditGroupId,
                                        this.state.groupLayersName
                                  );
    }

    this.autoUpdateLayerData();
    this.setState({
      isOpenSettingGroupLayers: false,
      hasShowWarningGroupLayerSetting: false,
    });
  };

  addNewGroupToLayerData = (categoryList,groupId,newGroup) => {
    let isDone = false;
    if(Array.isArray(categoryList) && categoryList.length >0 && isDone == false){
      for(let i=0; i< categoryList.length; i++){
        if(categoryList[i].id == groupId){
          if(categoryList[i].children == null){
            categoryList[i].children = [];
          }
          categoryList[i].children.push(newGroup);
          isDone = true;
        }

        if(Array.isArray(categoryList[i].children) && categoryList[i].children.length >0  && isDone == false){
          categoryList[i].children = this.addNewGroupToLayerData(categoryList[i].children,groupId, newGroup);
        }
      }
    }

    return categoryList;
  }

  updateGroupNameToLayerData = (categoryList, groupId, groupName) => {
    let isDone = false;
    if(Array.isArray(categoryList) && categoryList.length >0 && isDone == false){
        for(let i=0; i< categoryList.length; i++){
            if(categoryList[i].id == groupId){
              categoryList[i].folder_name = groupName;
              categoryList[i].folder_label = groupName;
              isDone = true;
            }

            if(Array.isArray(categoryList[i].children) && categoryList[i].children.length >0  && isDone == false){
                categoryList[i].children = this.updateGroupNameToLayerData(categoryList[i].children,groupId, groupName);
            }
        }
    }

    return categoryList;
  }

  autoUpdateLayerData = () => {
    this.props.UpdateLayers({ ...this.getLayerData.getLayerData() });
  };

  handleDeleteLayer = (event, layerId) => {
    /*
    * this.props.controlOpenlayer({
      type: OpenlayerCommand.ToggleDisplayLayer,
      option: {
        layer: this.getLayerData
            .getLayerGroupArrayLayerByIndex(groupLayerSelectedIndex)
            .splice(layerSelectedIndex, 1),
      },
    });
    * */
    this.props.layerData.layer_categories = this.removeLayerFromLayerData(
        this.props.layerData.layer_categories,layerId
    );
    this.autoUpdateLayerData();
  };

  getLayerData = {
    getLayerData: () => this.props.layerData,
    getListGroupLayers: () => this.props.layerData.layer_categories,
    getLayerGroupByIndex: (index) =>
      this.props.layerData.layer_categories[index],
    getLayerGroupArrayLayerByIndex: (index) =>
      this.props.layerData.layer_categories[index].layer_settings,
  };

  removeLayerFromLayerData = (dataList,layerId) => {
    if(Array.isArray(dataList) && dataList.length >0){
      for(let i=0;i<dataList.length;i++){
        let isUpdate = 0;
        let settingList = dataList[i].layer_settings;
        if(Array.isArray(settingList) && settingList.length >0){
          for(let j=0; j < settingList.length; j++){
            if(settingList[j].id == layerId){
              isUpdate = 1;
              settingList.splice(j,1);
            }
          }
          dataList[i].layer_settings = settingList;
        }
        if(isUpdate == 0 && Array.isArray(dataList[i].children) && dataList[i].children.length >0){
          dataList[i].children = this.removeLayerFromLayerData(dataList[i].children,layerId);
        }
      }
    }
    return dataList;
  }

  getLayerSettingListByGroupId = (groupId) => {
    let flatLayerList = this.props.flatLayerList;
    let returnSettingList = [];
    if(Array.isArray(flatLayerList) && flatLayerList.length >0){
      flatLayerList.map((layer) => {
        let layerSettingList = layer.layer_settings;
        if(layer.id == groupId){
          returnSettingList = layerSettingList;
        }
      })
    }
    return returnSettingList;
  }

  getLayerDataByLayerId = (layerId) => {
    let flatLayerList = this.props.flatLayerList;
    let returnLayer = [];
    if(Array.isArray(flatLayerList) && flatLayerList.length >0){
      flatLayerList.map((layer) => {
          let layerSettingList = layer.layer_settings;
          if(Array.isArray(layerSettingList) && layerSettingList.length >0){
            layerSettingList.map((data) => {
              if(data.id == layerId){
                if(data.display == null){
                  data.display = data.display_name;
                }
                if(data.filter == null){
                  data.filter = data.filter_name;
                }
                returnLayer = data;
              }
            })
          }
      })
    }
    return returnLayer;
  }

  handleSaveEditLayer = (dataLayerEdited) => {
    dataLayerEdited.display_name = null
    dataLayerEdited.filter_name = null
    let originalLayer = this.getLayerDataByLayerId(this.state.layerEditingId);
    if (dataLayerEdited.is_check != originalLayer.is_check){
      this.toggleDisplayLayerAction(dataLayerEdited);
    }

    this.props.layerData.layer_categories = this.updateEditedLayerToLayerDataList(
          this.props.layerData.layer_categories,dataLayerEdited,this.state.layerEditingId
    );
    this.autoUpdateLayerData();
  };

  openConfirmDeleteGroupLayer = () => {
    this.setState({
      openConfirmModal: true,
      confirmModalTitle: "Xóa nhóm layer",
      confirmModalHandleAccept: () => this.onClickDeleteGrouplayer(),
    });
  };

  openConfirmDeleteLayer = (event, layerId) => {
    this.setState({
      openConfirmModal: true,
      confirmModalTitle: "Xóa layer",
      confirmModalHandleAccept: () =>
        this.handleDeleteLayer(event, layerId),
    });
  };

  renderLayerName = (GroupLayer,indexGroup) => {
    return (
        <AccordionSummary>
          <div className="layer-group">
            <div className="layer-group-text">
              <span>{GroupLayer.folder_label}</span>
            </div>
            <div className="layer-group-action">
              <Tooltip title="Tạo nhóm layer mới">
                <IconButton
                    onClick={(event) => this.onClickAddNewGroup(event,GroupLayer.id,GroupLayer.level)}
                >
                  <AddCircleIcon fontSize="small" className="text-success" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Thêm layer mới">
                <IconButton
                    onClick={(event) =>
                        this.onClickOpenAddNewLayer(
                            event,
                            indexGroup,
                            GroupLayer.id
                        )
                    }
                >
                  <PlaylistAddIcon
                      fontSize="small"
                      className="text-success"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Thiết đặt">
                <IconButton
                    onClick={(event) =>
                        this.onClickSettingGroupLayer(
                            event,
                            indexGroup,
                            GroupLayer.id
                        )
                    }
                >
                  <TuneIcon
                      fontSize="small"
                      color="primary"
                  />
                </IconButton>
              </Tooltip>
            </div>
            <div className="cb"></div>
          </div>
        </AccordionSummary>
    )
  }

  renderLayerDetail = (GroupLayer,indexGroup) => {
      return (
          <AccordionDetails>
            <Container
                getChildPayload={(event) =>
                    this.getChildPayloadLayer(GroupLayer.id)
                }
                onDrop={(event) =>
                    this.onDropLayer(event,GroupLayer.id)
                }
                dragClass="drag-state-custom-ddb"
                groupName="Grouplayers"
            >
              {GroupLayer.layer_settings &&
              GroupLayer.layer_settings.map(
                  (layer, indexLayer) => (
                      <Draggable key={`${layer.id}_${indexLayer}`}>
                          <div className="layer-item" id={`layer-item-${GroupLayer.id}-${indexLayer}`} value={layer.id}>
                            <div className="layer-item-check">
                              <input
                                  type="checkbox"
                                  id={`checkbox-layer-index-${indexLayer}`}
                                  checked={layer.is_check}
                                  onChange={() => {}}
                                  onClick={() =>
                                      this.onClickCheckBoxLayer(
                                          layer,
                                          indexLayer,
                                          indexGroup
                                      )
                                  }
                              />
                            </div>
                            <div className="layer-item-text">
                              <span  title={layer.name} >{layer.name}</span>
                            </div>
                            <div className="layer-item-action">
                              <Tooltip title="Chỉnh sửa">
                                <IconButton
                                    onClick={(event) =>
                                        this.onClickEditLayer(
                                            event,
                                            layer.id
                                        )
                                    }
                                >
                                  <EditIcon
                                      color="primary"
                                      fontSize="small"
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Xóa layer">
                                <IconButton
                                    onClick={(event) =>
                                        this.openConfirmDeleteLayer(
                                            event,
                                            layer.id
                                        )
                                    }
                                >
                                  <DeleteIcon
                                      fontSize="small"
                                      className="text-danger"
                                  />
                                </IconButton>
                              </Tooltip>
                            </div>
                            <div className="cb"></div>
                          </div>
                      </Draggable>
                  )
              )}
            </Container>
          </AccordionDetails>
      )
  }

  increaseLayerLevel = () => {
    this.state.currentLayerLevel = this.state.currentLayerLevel + 1
  }
  reduceLayerLevel = () => {
    if(this.state.currentLayerLevel >0){
      this.state.currentLayerLevel = this.state.currentLayerLevel -1;
    }else{
      this.state.currentLayerLevel = 0;
    }
  }
  renderLayerGroup = (GroupLayers,indexGroup) => {
      return (
          <div className="layer-box">
            <Draggable key={indexGroup}>
                <Accordion>
                  {GroupLayers && (
                    this.renderLayerName(GroupLayers,indexGroup)
                  )}
                  {
                    GroupLayers.children && GroupLayers.children.length >0 && this.increaseLayerLevel()
                  }
                  {
                    GroupLayers.children && GroupLayers.children.length >0 &&
                    GroupLayers.children.map((child,childIndex) => {
                      return this.renderLayerGroup(child,childIndex);
                    })
                  }
                  {GroupLayers && (
                    this.renderLayerDetail(GroupLayers,indexGroup)
                  )}
                  {
                    GroupLayers.children && GroupLayers.children.length == 0 && this.reduceLayerLevel()
                  }
                </Accordion>
            </Draggable>
          </div>
      )
  }

  renderCreateLayerGroup = () => {
    return (
        <Accordion>
          <AccordionSummary
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
              className="position-relative base-map-header-summary"
          >
            <div
                className="row w-100 p-0 m-0"
                style={{ alignItems: "baseline" }}
            >
              <div className="col-8 p-0 m-0">
                <AccountTreeIcon className="icon-layer mr-2" />
                Layers
              </div>
              <div className="col-4 text-right p-0 m-0">
                <Tooltip title="Tạo nhóm layer mới">
                  <IconButton
                      onClick={(event) => this.onClickAddNewGroup(event,0)}
                  >
                    <AddCircleIcon fontSize="small" className="text-success" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className="d-block">
            <Divider />

            {this.props.layerData.haveData &&
            this.props.layerData.layer_categories.length === 0 && (
                <p className="text-center mt-3 mb-0">
                  Chưa có nhóm layer nào được tạo
                </p>
            )}

            <Container
                getChildPayload={this.getChildPayloadGrouplayer}
                onDrop={this.onDropGroupLayer}
                dragClass="drag-state-custom-ddb"
            >
              {this.props.layerData.haveData &&
              this.props.layerData.layer_categories.map(
                  (GroupLayers, indexGroup) => (
                      this.state.currentLayerLevel = 1,
                      this.renderLayerGroup(GroupLayers,indexGroup)
                  )
              )}
            </Container>
          </AccordionDetails>
        </Accordion>
    )
  }

  renderEditLayerGroup = () => {
    return (
      <Dialog
          maxWidth="sm"
          fullWidth={true}
          onClose={() => this.setState({ isOpenSettingGroupLayers: false })}
          aria-labelledby="base-map-add-alert-dialog-title"
          aria-describedby="alert-dialog-description"
          id="dialog-create-base-map"
          open={this.state.isOpenSettingGroupLayers}
      >
        <DialogTitle
            id="base-map-add-alert-dialog-title"
            onClose={() => this.setState({ isOpenSettingGroupLayers: false })}
        >
          {this.state.isCreateNewGroupLayers ? "Thêm Nhóm mới" : "Thiết lập"}
        </DialogTitle>
        <DialogContent className="content-custom-container" dividers>
          <InputLabel className="text-dark">Tên nhóm</InputLabel>
          <TextField
              helperText={
                this.state.hasShowWarningGroupLayerSetting &&
                !this.state.groupLayersName.trim()
                    ? "Không được để trống"
                    : ""
              }
              error={
                this.state.hasShowWarningGroupLayerSetting &&
                !this.state.groupLayersName.trim()
              }
              value={this.state.groupLayersName}
              onChange={(event) =>
                  this.setState({ groupLayersName: event.target.value })
              }
              fullWidth
              variant="outlined"
              size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button
              variant="contained"
              onClick={() => this.setState({ isOpenSettingGroupLayers: false })}
              color="error"
          >
            Đóng
          </Button>

          {!this.state.isCreateNewGroupLayers && (
              <Button
                  variant="contained"
                  onClick={() => this.openConfirmDeleteGroupLayer()}
                  color="secondary"
              >
                <DeleteIcon className="mr-1" />
                Xóa nhóm
              </Button>
          )}

          <Button
              variant="contained"
              onClick={() => this.handleClickAcceptSettingGrouplayer()}
              color="primary"
          >
            Chấp nhận
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderSettingLayer = () => {
    return (
        <Dialog
            maxWidth="lg"
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth={true}
            id="dialog-create-base-map"
            open={this.state.isOpenSettingLayer}
            onClose={() => this.setState({ isOpenSettingLayer: false })}
        >
          <DialogTitle disableTypography id="base-map-add-alert-dialog-title">
            <Typography variant="h6">Tạo layer</Typography>
            <IconButton
                aria-label="close"
                onClick={() => this.setState({ isOpenSettingLayer: false })}
                style={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  color: "#9e9e9e",
                }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent
              className="content-custom-container"
              id="content-custom-container-setting-layer"
              dividers
          >
            {this.state.isCreateNewLayer
                ? this.state.isOpenSettingLayer && (
                <ModalLayerSetting
                    parentId = {this.state.parentId}
                    planningId={this.props.planningId}
                    handleAddNewLayer={(newLayer) =>
                        this.handleAddNewLayer(newLayer)
                    }
                    closeModal={() =>
                        this.setState({ isOpenSettingLayer: false })
                    }
                />
            )
                : this.state.isOpenSettingLayer && (
                <ModalEditSettingLayer
                    setLayerData={(data) => this.handleSaveEditLayer(data)}
                    layerData={this.getLayerDataByLayerId(this.state.layerEditingId)}
                    closeModal={() =>
                        this.setState({ isOpenSettingLayer: false })
                    }
                />
            )}
          </DialogContent>
        </Dialog>
    )
  }

  renderConfirmModal = () => {
    return (
        <ConfirmModalView
            open={this.state.openConfirmModal}
            handleClose={() => this.setState({ openConfirmModal: false })}
            title={this.state.confirmModalTitle}
            handleAccept={this.state.confirmModalHandleAccept}
        />
    )
  }

  render() {
    return (
      <div className="left-menu-layer-control-container mt-2">
        {this.renderCreateLayerGroup()}
        {this.renderEditLayerGroup()}
        {this.renderSettingLayer()}
        {this.renderConfirmModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  layerData: state.initMap.layers,
  layerMapSetting: state.initMap.layerMapSetting,
  flatLayerList: state.initMap.flatLayerList,
  controlOpenlayer: state.openLayer.handleOutSideFunction,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      UpdateLayers: InitMapStore.UpdateLayer,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenderLayerControlView);
