const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
        api: "https://htpccc-api.cgis.asia/",
        domainAdminSite: "http://localhost:3000",
        domainUserSide: "http://localhost:3000",
        domainName: "",
  },
  production: {
      api: "https://htpccc-api.cgis.asia/",
      domainAdminSite: "https://admin.htpccc.cgis.asia",
      domainUserSide: "https://admin.htpccc.cgis.asia",
      domainName: "htpccc.cgis.asia",
  },
};

module.exports = apiEnvironment[env];
